import React, { useCallback, useState } from "react";
import { useMutation, useApolloClient } from "@apollo/client/react";
import Button from "@material-ui/core/Button";
import {
	useNotify,
	minValue,
	useRedirect,
	DateTimeInput,
	required,
} from "react-admin";
import type { GetCountAndAddRemovesHasLossQuery } from "~/api/get-count-and-add-removes-has-loss-query.ts";
import getCountAndAddRemovesHasLossQuery from "~/api/get-count-and-add-removes-has-loss-query.ts";
import createCountInventoryMutation from "~/api/create-count-inventory-mutation.ts";
import Modal from "~/components/modal.tsx";
import type { InventoryFormProps } from "./inventory-form.tsx";
import InventoryForm, { inventoryFormValuesToApi } from "./inventory-form.tsx";
import integer from "./integer.ts";
import { useInventoryBasePlateSizes } from "~/hooks/base-plate-sizes.tsx";

function CountForm(
	props: Pick<InventoryFormProps, "colours" | "containers" | "inventoryState">,
) {
	const notify = useNotify();
	const redirect = useRedirect();
	const [createCount, createCountResult] = useMutation(
		createCountInventoryMutation,
		{
			onCompleted() {
				notify("Inventory item added", "info");
				redirect("list", "/inventoryItems");
			},
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);

	const [pendingLossVariables, setPendingLossVariables] =
		useState<any>(undefined);
	const [loadingHasLoss, setLoadingHasLoss] = useState(false);
	const apolloClient = useApolloClient();
	const basePlateSizes = useInventoryBasePlateSizes();
	const onSave = useCallback(
		async (sourceVariables: any) => {
			const variables = inventoryFormValuesToApi(
				basePlateSizes,
				sourceVariables,
			);
			setLoadingHasLoss(true);
			try {
				const { data } =
					await apolloClient.query<GetCountAndAddRemovesHasLossQuery>({
						query: getCountAndAddRemovesHasLossQuery,
						variables,
						fetchPolicy: "no-cache",
					});
				if (data.countAndAddRemovesHasLoss) {
					setPendingLossVariables(variables);
					return;
				}
				await createCount({
					variables: {
						...variables,
						isLoss: false,
					},
				});
			} catch (e) {
				notify((e as any).toString(), "error");
			} finally {
				setLoadingHasLoss(false);
			}
		},
		[createCount, apolloClient, basePlateSizes, notify],
	);
	const onReportLoss = async (isLoss: boolean) => {
		await createCount({
			variables: {
				...pendingLossVariables,
				isLoss,
			},
		});
		setPendingLossVariables(undefined);
	};

	return (
		<>
			<Modal
				id="pending-loss"
				open={!!pendingLossVariables}
				onClose={() => setPendingLossVariables(undefined)}
				title="Reporting a Loss"
				description="This will remove items from the inventory. Would you like to report a loss?"
				buttons={[
					<Button
						key="no"
						type="button"
						color="secondary"
						variant="outlined"
						onClick={() => onReportLoss(false)}
					>
						No
					</Button>,
					<Button
						key="yes"
						type="button"
						color="primary"
						variant="contained"
						onClick={() => onReportLoss(true)}
					>
						Yes
					</Button>,
				]}
			/>
			<InventoryForm
				actionName="Set Inventory Counts"
				onSave={onSave}
				saving={createCountResult.loading || loadingHasLoss}
				cellStep={1}
				cellValidate={[integer(), minValue(0)]}
				{...props}
			>
				<DateTimeInput
					label="Date to apply count"
					source="date"
					validate={required()}
				/>
			</InventoryForm>
		</>
	);
}

export default CountForm;
