import React from "react";
import type { Record } from "react-admin";
import {
	ImageField,
	List,
	Datagrid,
	TextField,
	ShowButton,
	Show,
	DateField,
	SimpleShowLayout,
	FileField,
	UrlField,
	FunctionField,
	DeleteButton,
	Button,
	useNotify,
	useRefresh,
} from "react-admin";
import Shop from "@material-ui/icons/Shop";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/client/react";
import { gql } from "@apollo/client";
import LoadMorePagination from "~/components/load-more-pagination.tsx";
import ListThumbnailField from "~/components/list-thumbnail-field.tsx";

function ReCreateShopifyProductButton({ record }: any) {
	const notify = useNotify();
	const refresh = useRefresh();
	const [reCreateShopifyProduct, { loading }] = useMutation(
		gql`
			mutation createShopifyProduct($id: ID!) {
				createShopifyProduct(id: $id)
			}
		`,
		{
			onCompleted() {
				notify("Shopify product changed", "info");
				refresh();
			},
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);
	return (
		<Button
			type="button"
			onClick={() =>
				reCreateShopifyProduct({
					variables: { id: record.id },
				})
			}
			label="Re-create shopify product"
			disabled={loading}
		>
			<Shop />
		</Button>
	);
}

function BrickArtDesignList(props: any) {
	return (
		<List
			{...props}
			title="Brick art designs"
			bulkActionButtons={false}
			sort={{ field: "createdAt", order: "DESC" }}
			pagination={<LoadMorePagination showTotal />}
		>
			<Datagrid>
				<TextField source="id" sortable={false} />
				<TextField source="title" sortable />
				<ListThumbnailField source="mediumImageUrl" sortable={false} />
				<DateField source="createdAt" showTime sortable />
				<ShowButton />
				<ReCreateShopifyProductButton />
				<DeleteButton undoable={false} />
			</Datagrid>
		</List>
	);
}

function BrickArtDesignShow(props: any) {
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<TextField source="title" />
				<TextField source="description" />
				<TextField source="tags" />
				<DateField source="createdAt" showTime />
				<TextField source="shopifyProductId" />
				<UrlField
					source="shopifyAdminProductUrl"
					target="_blank"
					rel="noreferrer noopener"
				/>
				<FunctionField
					label="Source"
					render={(record?: Record) => {
						switch (record?.source?.type) {
							case "saved":
								return (
									<Link to={`/savedProjects/${record.source.id}/show`}>
										Saved project {record.source.id}
									</Link>
								);
							case "import":
								return (
									<div>
										Synced from main brick art store: {record.source.id}
									</div>
								);
							default:
								return <div />;
						}
					}}
				/>
				<FileField
					source="sourceImageUrl"
					target="_blank"
					rel="noopener noreferrer"
					title="Source image"
				/>
				<ImageField source="mediumImageUrl" />
			</SimpleShowLayout>
		</Show>
	);
}

export { BrickArtDesignList, BrickArtDesignShow };
