import React from "react";
import type { Record } from "react-admin";
import { FunctionField } from "react-admin";
import type {
	InjectedFieldProps,
	PublicFieldProps,
} from "ra-ui-materialui/lib/field/types";
import type { PictureSummaryFragment } from "~/api/picture-summary-fragment.ts";

function PictureSummaryField({
	source,
	...props
}: PublicFieldProps & InjectedFieldProps) {
	return (
		<FunctionField
			{...props}
			source={source}
			render={(record?: Record) => {
				if (!record) {
					return <div />;
				}
				// Technically this won't happen yet for "show" cases. But there's a quick population of
				// show with the list data before it loads fully - in that case picture mightn't be loaded
				const picture = (record as any)[
					source as any
				] as PictureSummaryFragment;
				if (!picture) {
					return <div />;
				}

				const {
					basePlateSize,
					numberOfBasePlates,
					brightness,
					contrast,
					saturation,
					enhanceDetailName,
					buildNumberOfColours,
					enhanceFaces,
					fixFaceColours,
					removeBackground,
				} = picture;
				return (
					<ul>
						<li>BasePlate Size: {basePlateSize}</li>
						<li>
							Number of BasePlates (width x height): {numberOfBasePlates.width}x
							{numberOfBasePlates.height}
						</li>
						<li>Number of Colours: {buildNumberOfColours}</li>
						<li>Brightness: {brightness}</li>
						<li>Contrast: {contrast}</li>
						<li>Saturation: {saturation}</li>
						<li>Enhance Detail: {enhanceDetailName}</li>
						<li>Enhance Faces: {enhanceFaces ? "Yes" : "No"}</li>
						<li>Fix Face Colours: {fixFaceColours ? "Yes" : "No"}</li>
						<li>
							Remove Background?:{" "}
							{removeBackground ? `Yes - ${removeBackground.newColour}` : "No"}
						</li>
					</ul>
				);
			}}
		/>
	);
}

PictureSummaryField.defaultProps = {
	addLabel: true,
};

export default PictureSummaryField;
