import type { QueryHookOptions } from "@apollo/client/react";
import { useQuery } from "@apollo/client/react";
import { useNotify } from "react-admin";
import { gql } from "@apollo/client";
import type {
	GetInventoryStateQuery,
	GetInventoryStateQueryVariables,
} from "~/api/get-inventory-state-query.generated.ts";
import getInventoryStateQuery from "~/api/get-inventory-state-query.graphql";
import fullInventoryStateFragment from "~/api/full-inventory-state-fragment.graphql";

function useInventoryState(
	date?: Date,
	options?: Omit<
		QueryHookOptions<GetInventoryStateQuery, GetInventoryStateQueryVariables>,
		"variables"
	>,
) {
	const notify = useNotify();
	const { data, loading } = useQuery<
		GetInventoryStateQuery,
		GetInventoryStateQueryVariables
	>(
		gql`
			${getInventoryStateQuery}
			${fullInventoryStateFragment}
		`,
		{
			variables: { date: date?.toISOString() },
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
			...options,
		},
	);
	return { inventoryState: data?.inventoryState, loading };
}

export default useInventoryState;
