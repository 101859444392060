fragment fullInventoryState on AdminInventoryState {
	items {
		type {
			type
			size
			colourId
			containerId
		}
		cost
		quantity
		history {
			cost
			quantity
		}
	}
}