import { gql } from "@apollo/client";
import type { QueryHookOptions } from "@apollo/client/react";
import { useQuery } from "@apollo/client/react";
import { useNotify } from "react-admin";
import type {
	GetInventoryStateLegacyQuery,
	GetInventoryStateLegacyQueryVariables,
} from "~/api/get-inventory-state-legacy-query.generated.ts";
import getInventoryStateLegacyQuery from "~/api/get-inventory-state-legacy-query.graphql";
import fullInventoryStateFragment from "~/api/full-inventory-state-fragment.graphql";

function useInventoryLegacyState(
	date?: Date,
	options?: Omit<
		QueryHookOptions<
			GetInventoryStateLegacyQuery,
			GetInventoryStateLegacyQueryVariables
		>,
		"variables"
	>,
) {
	const notify = useNotify();
	const { data, loading } = useQuery<
		GetInventoryStateLegacyQuery,
		GetInventoryStateLegacyQueryVariables
	>(
		gql`
			${getInventoryStateLegacyQuery}
			${fullInventoryStateFragment}
		`,
		{
			variables: { date: date?.toISOString() },
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
			...options,
		},
	);
	return { inventoryState: data?.inventoryStateLegacy, loading };
}

export default useInventoryLegacyState;
