import { gql } from "@apollo/client";

const createCountInventoryMutation = gql`
	mutation createCountInventoryMutation(
		$date: DateTime!
		$containers: [CreatecountinventoryContainers!]!
		$baseplates: [CreatecountinventoryBaseplates!]!
		$brickSeparators: Int
		$logoTile: Int
		$paddedEnvelope: Int
		$singleBox: Int
		$doubleBox: Int
		$tripleBox: Int
		$smallFlatpackBox: Int
		$packaging: Int
		$paperA5: Int
		$paperA4: Int
		$paperLetter: Int
		$paperA3: Int
		$isLoss: Boolean!
	) {
		createCountInventory(
			date: $date
			containers: $containers
			brickSeparators: $brickSeparators
			logoTile: $logoTile
			paddedEnvelope: $paddedEnvelope
			singleBox: $singleBox
			doubleBox: $doubleBox
			tripleBox: $tripleBox
			smallFlatpackBox: $smallFlatpackBox
			packaging: $packaging
			baseplates: $baseplates
			paperA5: $paperA5
			paperA4: $paperA4
			paperLetter: $paperLetter
			paperA3: $paperA3
			isLoss: $isLoss
		) {
			__typename
			id
		}
	}
`;

export default createCountInventoryMutation;
