import type { ReactElement } from "react";
import React from "react";

type ImageUrlFieldProps = {
	readonly className?: string;
	readonly record?: string;
};

function ImageUrlField({
	record,
	className,
}: ImageUrlFieldProps): ReactElement {
	return (
		<img
			className={className}
			src={record}
			style={{ maxWidth: 250, maxHeight: 200 }}
			alt={record}
		/>
	);
}

export default ImageUrlField;
