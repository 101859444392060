import { useMutation } from "@apollo/client";
import React from "react";
import { Button, useNotify } from "react-admin";
import type {
	SyncCustomDesignWeightsAndCostsMutation,
	SyncCustomDesignWeightsAndCostsMutationVariables,
} from "./sync-custom-design-weights-and-costs-mutation.generated.ts";
import syncCustomDesignWeightsAndCostsMutation from "./sync-custom-design-weights-and-costs-mutation.graphql";

function SyncWeightsAndCosts() {
	const notify = useNotify();
	const [syncCustomDesignWeightAndCosts, { loading }] = useMutation<
		SyncCustomDesignWeightsAndCostsMutation,
		SyncCustomDesignWeightsAndCostsMutationVariables
	>(syncCustomDesignWeightsAndCostsMutation, {
		onCompleted() {
			notify("Syncing process started", { type: "info" });
		},
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
	});

	return (
		<Button
			label="Manually sync custom design weights and costs"
			onClick={() => syncCustomDesignWeightAndCosts()}
			variant="contained"
			color="primary"
			disabled={loading}
		/>
	);
}

export default SyncWeightsAndCosts;
