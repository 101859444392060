import React from "react";
import { TopToolbar, Button, useNotify, downloadCSV } from "react-admin";
import GetApp from "@material-ui/icons/GetApp";
import { useLazyQuery } from "@apollo/client/react";
import type { DocumentNode } from "@apollo/client/core";

type InventoryHistoryActionsProps = {
	readonly query: DocumentNode;
	readonly getData: (data: any) => any;
	readonly baseFilename: string;
};

function InventoryHistoryActions({
	query,
	getData,
	baseFilename,
}: InventoryHistoryActionsProps) {
	const notify = useNotify();
	const [getExport, { loading }] = useLazyQuery(query, {
		fetchPolicy: "no-cache",
		onCompleted(data) {
			downloadCSV(getData(data), baseFilename);
		},
		onError(e) {
			notify(e.message, "error");
		},
	});

	return (
		<TopToolbar>
			<Button
				type="button"
				label="Export"
				onClick={() => getExport()}
				disabled={loading}
			>
				<GetApp />
			</Button>
		</TopToolbar>
	);
}

export default InventoryHistoryActions;
