import type { ReactElement } from "react";
import React from "react";
import type { Record } from "react-admin";
import { FunctionField } from "react-admin";

function UserSummaryField({ source, ...props }: any): ReactElement {
	return (
		<FunctionField
			{...props}
			source={source}
			render={(record?: Record) => {
				const user = record ? record[source] : undefined;
				if (!user) {
					return <div>Guest</div>;
				}

				return (
					<ul>
						<li>ID: {user.id}</li>
						<li>Email address: {user.emailAddress}</li>
						<li>Given name: {user.givenName}</li>
						<li>Middle name: {user.middleName}</li>
						<li>Family name: {user.familyName}</li>
						<li>Country: {user.country}</li>
						<li>Birth date: {user.birthDate}</li>
						<li>How heard about: {user.howHeard}</li>
						<li>Joined at: {user.joinedAt}</li>
					</ul>
				);
			}}
		/>
	);
}

UserSummaryField.defaultProps = {
	addLabel: true,
};

export default UserSummaryField;
