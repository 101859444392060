import type { ReactElement, ReactNode } from "react";
import React, { createContext, useContext } from "react";

const Context = createContext<string | undefined>(undefined);

type ShopifyUrlProviderProps = {
	readonly shopifyUrl: string;
	readonly children: ReactNode;
};

function ShopifyUrlProvider({
	shopifyUrl,
	children,
}: ShopifyUrlProviderProps): ReactElement {
	return <Context.Provider value={shopifyUrl}>{children}</Context.Provider>;
}

function useShopifyUrl(): string {
	const baseUrl = useContext(Context);
	if (!baseUrl) {
		throw new Error("No shopify url context");
	}
	return baseUrl;
}

export { ShopifyUrlProvider, useShopifyUrl };
