import React, { useState } from "react";
import { TextInput, useNotify, required, minLength } from "react-admin";
import { Form } from "react-final-form";
import { Button, FormControl } from "@material-ui/core";
import { useQuery } from "@apollo/client/react";
import getSumTotalByEmailQuery from "./get-sum-total-by-email-query.graphql";
import type {
	GetSumTotalByEmailQuery,
	GetSumTotalByEmailQueryVariables,
} from "./get-sum-total-by-email-query.generated.ts";

function CalculateInventorySold() {
	const [email, setEmail] = useState<string | undefined>(undefined);
	const notify = useNotify();

	const { loading, data } = useQuery<
		GetSumTotalByEmailQuery,
		GetSumTotalByEmailQueryVariables
	>(getSumTotalByEmailQuery, {
		variables: { email: email || "" },
		skip: !email,
		onError: (error) => {
			notify(error.message, { type: "error" });
		},
	});

	return (
		<div>
			<Form
				onSubmit={(values: { email: string }) => {
					setEmail(values.email.toLowerCase());
				}}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<FormControl>
							<TextInput
								label="Email Address"
								source="email"
								validate={[required(), minLength(5)]}
								disabled={loading}
								fullWidth
							/>

							<Button
								type="submit"
								color="primary"
								variant="contained"
								disabled={loading}
							>
								{loading ? "Calculating..." : "Calculate Total"}
							</Button>
						</FormControl>
					</form>
				)}
			/>
			{data && (
				<div style={{ marginTop: "1em", textAlign: "center" }}>
					<h3>Total Value of Orders:</h3>
					<p>${data.getSumTotalByEmail.total.toFixed(2)}</p>
				</div>
			)}
		</div>
	);
}

export default CalculateInventorySold;
