import { formatMoneyRounded } from "~/utils/money.ts";
import { calculateItemTotalCost } from "./calculate-total-cost.ts";
import type { AdmininventorystateItems } from "~/api/types.ts";

function formatInventoryStateCost(item?: AdmininventorystateItems): string {
	if (!item) {
		return "0 / -";
	}
	const { quantity, cost } = item;
	const formattedQuantity = quantity.toLocaleString();
	if (cost === undefined || cost === null) {
		return `${formattedQuantity} / -`;
	}
	return `${formattedQuantity} / ${formatMoneyRounded(cost)}`;
}

function formatInventoryStateTotalCost(
	item?: AdmininventorystateItems,
): string {
	if (!item) {
		return `0 / ${formatMoneyRounded("0")}`;
	}
	const { quantity } = item;
	const formattedQuantity = quantity.toLocaleString();
	const totalCost = calculateItemTotalCost(item);
	return `${formattedQuantity} / ${formatMoneyRounded(totalCost)}`;
}

export { formatInventoryStateCost, formatInventoryStateTotalCost };
