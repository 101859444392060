import React from "react";
import type { Record } from "react-admin";
import {
	DateField,
	List,
	Datagrid,
	TextField,
	DeleteButton,
	ShowButton,
	FunctionField,
} from "react-admin";
import Undo from "@material-ui/icons/Undo";
import { gql } from "@apollo/client/core";
import LoadMorePagination from "~/components/load-more-pagination.tsx";
import { formatInventoryItemFullValue } from "../inventory-shared/format-inventory-item-value.ts";
import InventoryHistoryActions from "../inventory-shared/inventory-history-actions.tsx";

function ReverseButton(props: any) {
	const { record } = props;
	if (record.type === "cost-price") {
		return null;
	}

	return (
		<DeleteButton
			undoable={false}
			label="Reverse"
			icon={<Undo />}
			size="small"
			{...props}
		/>
	);
}

const exportQuery = gql`
	query HistoryExportQuery {
		inventoryItemsExport
	}
`;

function InventoryHistory(props: any) {
	return (
		<List
			{...props}
			title="Inventory history"
			bulkActionButtons={false}
			sort={{ field: "createdAt", order: "DESC" }}
			pagination={<LoadMorePagination showTotal />}
			actions={
				<InventoryHistoryActions
					query={exportQuery}
					getData={(data) => data.inventoryItemsExport}
					baseFilename="inventory-history"
				/>
			}
		>
			<Datagrid>
				<DateField source="id" sortable={false} label="Timestamp" showTime />
				<DateField source="createdAt" sortable={false} showTime />
				<TextField source="reason" sortable={false} />
				<FunctionField
					source="summary"
					sortable={false}
					render={(record?: Record) => {
						if (!record || !record.summary) {
							return <div />;
						}
						return (
							<div>
								{record.summary.largestItems
									.map(
										(item: any) =>
											`${item.label} - ${formatInventoryItemFullValue(
												record,
												item.value,
											)}`,
									)
									.join(", ")}
								{record.summary.totalItems >
									record.summary.largestItems.length && "..."}
							</div>
						);
					}}
				/>
				<ReverseButton />
				<ShowButton />
			</Datagrid>
		</List>
	);
}

export default InventoryHistory;
