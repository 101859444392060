import { gql } from "@apollo/client";

export default {
	GET_LIST: {
		name: "inventoryItemsLegacyList",
		useListAndCount: true,
		itemFragment: gql`
			fragment inventoryItemsLegacyItem on AdminInventoryItem {
				__typename
				id
				reason
				reversalOfId
				type
				summary {
					totalItems
					largestItems {
						label
						value
					}
				}
				createdAt
			}
		`,
	},
	GET_ONE: {
		name: "inventoryItemLegacyById",
		itemFragment: gql`
			fragment inventoryItemLegacy on AdminInventoryItem {
				__typename
				id
				type
				reason
				isLoss
				packaging
				brickSeparators
				logoTile
				paddedEnvelope
				singleBox
				doubleBox
				tripleBox
				smallFlatpackBox
				paperA5
				paperA4
				paperLetter
				paperA3
				baseplates {
					size
					value
				}
				reversalOfId
				containers {
					containerId
					colourId
					value
				}
				createdAt
			}
		`,
	},
} as any;
// } as GraphQLResourceDefinition;
