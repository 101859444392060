import type { Validator } from "react-admin";

// Copied from react-admin
function isEmpty(value: any) {
	return (
		typeof value === "undefined" ||
		value === null ||
		value === "" ||
		(Array.isArray(value) && value.length === 0)
	);
}

function integerValidate(value: any) {
	if (isEmpty(value)) {
		return undefined;
	}

	const num = Number(value);
	if (Number.isNaN(num)) {
		return undefined;
	}

	if (num === Math.round(num)) {
		return undefined;
	}

	return `Expected an integer, got ${String(value)}`;
}

function integer(): Validator {
	return integerValidate;
}

export default integer;
