import type { OperationVariables } from "@apollo/client";
import { gql } from "@apollo/client";
import { GET_LIST, GET_ONE, CREATE, UPDATE, DELETE } from "react-admin";
import { omit } from "lodash-es";
import type { BuildQueryResult } from "ra-data-graphql";
import exportMaxResults from "~/utils/export-max-results.ts";
import {
	getMutationByName,
	getRootQueryFieldByName,
} from "./schema-helpers.ts";
import resources from "./resources.ts";
import type {
	GraphQLResourceDefinition,
	GraphQLArgType,
	GraphQLArgTypeType,
	QueryType,
} from "./graphql-resource-definition.d.ts";

type GetListResponseType = {
	data: Record<string, readonly unknown[]>;
};

type GetOneResponseType = {
	data: Record<string, unknown>;
};

type FetchType = "GET_LIST" | "GET_ONE" | "CREATE" | "UPDATE" | "DELETE";

function buildTypeName(type: GraphQLArgTypeType): string {
	switch (type.kind) {
		case "NON_NULL":
			return `${buildTypeName(type.ofType as any)}!`;
		case "LIST":
			return `[${buildTypeName(type.ofType as any)}]`;
		case "SCALAR":
		case "INPUT_OBJECT":
		default:
			return type.name;
	}
}

function buildGraphQLArguments(graphQLArgs: readonly GraphQLArgType[]): {
	argTypes: string;
	args: string;
} {
	const argTypes: string[] = [];
	const args: string[] = [];
	graphQLArgs.forEach((arg) => {
		const argTypeName = buildTypeName(arg.type);
		argTypes.push(`$${arg.name}: ${argTypeName}`);
		args.push(`${arg.name}: $${arg.name}`);
	});

	return {
		argTypes: argTypes.length > 0 ? `(${argTypes.join(", ")})` : "",
		args: args.length > 0 ? `(${args.join(", ")})` : "",
	};
}

function buildListQueryBase(
	introspectionSchema: any,
	query: QueryType,
	variables: OperationVariables | undefined,
): BuildQueryResult {
	const { itemFragment, name: resourceGraphQLName } = query;
	const itemFragmentName = itemFragment.definitions[0].name.value;
	const field = getRootQueryFieldByName(
		introspectionSchema,
		resourceGraphQLName,
	);
	const args = buildGraphQLArguments(field.args);
	const gQlQuery = query.useListAndCount
		? gql`query ${resourceGraphQLName}Query${args.argTypes} {
        ${resourceGraphQLName}${args.args} {
          data {
            ...${itemFragmentName}
          }
          total
        }
      }
      ${itemFragment}`
		: gql`query ${resourceGraphQLName}Query${args.argTypes} {
        ${resourceGraphQLName}${args.args} {
          ...${itemFragmentName}
        }
      }
      ${itemFragment}`;
	return {
		query: gQlQuery,
		variables,
		parseResponse: (response: GetListResponseType) => {
			const listResponse = response.data[resourceGraphQLName];
			if (query.useListAndCount) {
				return listResponse;
			}
			if (import.meta.env.NODE_ENV === "development") {
				console.warn("Using old style list", listResponse);
			}
			return {
				data: listResponse,
				total: listResponse.length,
			};
		},
	};
}

function buildListQuery(
	introspectionSchema: any,
	resource: GraphQLResourceDefinition,
	variables: OperationVariables | undefined,
) {
	return buildListQueryBase(introspectionSchema, resource.GET_LIST, variables);
}

function buildExportQuery(
	introspectionSchema: any,
	resource: GraphQLResourceDefinition,
	variables: OperationVariables | undefined,
) {
	return buildListQueryBase(
		introspectionSchema,
		resource.GET_EXPORT ?? resource.GET_LIST,
		variables,
	);
}

function buildGetOneQuery(
	introspectionSchema: any,
	resource: GraphQLResourceDefinition,
	variables: OperationVariables | undefined,
) {
	const resourceGraphQLName = resource.GET_ONE.name;
	const { itemFragment } = resource.GET_ONE;
	const field = getRootQueryFieldByName(
		introspectionSchema,
		resourceGraphQLName,
	);
	const args = buildGraphQLArguments(field.args);
	const itemFragmentName = itemFragment.definitions[0].name.value;
	return {
		query: gql`query ${resourceGraphQLName}Query${args.argTypes} {
      ${resourceGraphQLName}${args.args} {
        ...${itemFragmentName}
      }
    }
    ${itemFragment}`,
		variables,
		parseResponse: ({ data }: GetOneResponseType) => ({
			data: data[resourceGraphQLName],
		}),
	};
}

function buildCreateStyleMutation(
	introspectionSchema: any,
	resource: any,
	resourceGraphQLName: string,
	rawVariables: Record<string, unknown>,
): BuildQueryResult {
	const mutation = getMutationByName(introspectionSchema, resourceGraphQLName);
	const resourceGraphQLArgs = buildGraphQLArguments(mutation.args);
	const { itemFragment } = resource.GET_ONE;
	const itemFragmentName = itemFragment.definitions[0].name.value;
	const variables =
		resource.CREATE.argsType === "flat"
			? (rawVariables.input as OperationVariables)
			: rawVariables;
	return {
		query: gql`mutation ${resourceGraphQLName}Mutation${resourceGraphQLArgs.argTypes} {
			${resourceGraphQLName}${resourceGraphQLArgs.args} {
				...${itemFragmentName}
			}
		}
		${itemFragment}`,
		variables,
		parseResponse: ({ data }: GetOneResponseType) => ({
			data: data[resourceGraphQLName],
		}),
	};
}

function buildCreateQuery(
	introspectionSchema: any,
	resource: any,
	input: Record<string, unknown>,
): BuildQueryResult {
	return buildCreateStyleMutation(
		introspectionSchema,
		resource,
		resource.CREATE.name,
		{ input },
	);
}

function buildUpdateStyleMutation(
	introspectionSchema: any,
	resource: any,
	resourceGraphQLName: string,
	input: Record<string, unknown>,
) {
	const mutation = getMutationByName(introspectionSchema, resourceGraphQLName);
	const resourceGraphQLArgs = buildGraphQLArguments(mutation.args);
	// eslint-disable-next-line @typescript-eslint/naming-convention
	const { id, ...updateInput } = omit(input, "__typename");
	const { itemFragment } = resource.GET_ONE;
	const itemFragmentName = itemFragment.definitions[0].name.value;
	return {
		query: gql`mutation ${resourceGraphQLName}Mutation${resourceGraphQLArgs.argTypes} {
      ${resourceGraphQLName}${resourceGraphQLArgs.args} {
        ...${itemFragmentName}
      }
    }
    ${itemFragment}`,
		variables: { id, input: updateInput },
		parseResponse: ({ data }: GetOneResponseType) => ({
			data: data[resourceGraphQLName],
		}),
	};
}

function buildUpdateQuery(
	introspectionSchema: any,
	resource: any,
	input: Record<string, unknown>,
) {
	return buildUpdateStyleMutation(
		introspectionSchema,
		resource,
		resource.UPDATE.name,
		input,
	);
}

function buildDeleteQuery(
	introspectionSchema: any,
	resource: any,
	variables: OperationVariables | undefined,
): BuildQueryResult {
	if (!resource.DELETE) {
		throw new Error("No delete mutation found");
	}
	const resourceGraphQLName = resource.DELETE.name;
	return {
		query: gql`mutation ${resourceGraphQLName}Mutation($id: ID!) {
      ${resourceGraphQLName}(id:$id)
    }`,
		variables,
		parseResponse: ({ data }: GetOneResponseType) => ({
			data: data[resourceGraphQLName],
		}),
	};
}

const buildQuery =
	(introspectionSchema: any) =>
	(
		raFetchType: string,
		resourceName: string,
		params: any,
	): BuildQueryResult => {
		const resource = resources[resourceName];
		if (!resource) {
			throw new Error(
				`No resource for name ${resourceName}. Got ${Object.keys(
					resources,
				).join(", ")}`,
			);
		}

		switch (raFetchType) {
			case GET_LIST:
				if (params.pagination?.perPage === exportMaxResults) {
					return buildExportQuery(introspectionSchema, resource, params);
				}
				return buildListQuery(introspectionSchema, resource, params);
			case GET_ONE:
				return buildGetOneQuery(introspectionSchema, resource, params);
			case CREATE:
				return buildCreateQuery(introspectionSchema, resource, params.data);
			case UPDATE:
				return buildUpdateQuery(introspectionSchema, resource, params.data);
			case DELETE:
				return buildDeleteQuery(introspectionSchema, resource, params);
			default:
				throw new Error(`Unrecognised query ${resourceName} -> ${raFetchType}`);
		}
	};

export type { FetchType };
export default buildQuery;
