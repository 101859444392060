import type { ReactElement, ReactNode } from "react";
import React, { createContext, useContext } from "react";

const Context = createContext<string | undefined>(undefined);

type MediaBaseUrlProviderProps = {
	readonly mediaBaseUrl: string;
	readonly children: ReactNode;
};

export function MediaBaseUrlProvider({
	mediaBaseUrl,
	children,
}: MediaBaseUrlProviderProps): ReactElement {
	return <Context.Provider value={mediaBaseUrl}>{children}</Context.Provider>;
}

export function useMediaBaseUrl(): string {
	const baseUrl = useContext(Context);
	if (!baseUrl) {
		throw new Error("No media base url context");
	}
	return baseUrl;
}
