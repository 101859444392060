import React, { StrictMode } from "react";
import { render } from "react-dom";
import { init as sentryInit, browserTracingIntegration } from "@sentry/react";
import App from "~/app/app.tsx";
import ensureEnv from "~/utils/ensure-env.ts";

const sentryDsn = import.meta.env.VITE_SENTRY_DSN;
if (sentryDsn) {
	const sentryEnvironment = import.meta.env.VITE_SENTRY_ENVIRONMENT;
	const sentryRelease = import.meta.env.VITE_SENTRY_RELEASE;
	sentryInit({
		dsn: sentryDsn,
		environment: sentryEnvironment,
		release: sentryRelease,
		integrations: [browserTracingIntegration()],
		normalizeDepth: 6,

		// We recommend adjusting this value in production, or using tracesSampler
		// for finer control
		// tracesSampleRate: 1.0,
	});
}

render(
	<StrictMode>
		<App
			apiUrl={ensureEnv("VITE_API_URL")}
			shopifyUrl={ensureEnv("VITE_SHOPIFY_URL")}
			userPoolClientId={ensureEnv("VITE_USER_POOL_APP_CLIENT_ID")}
			userPoolId={ensureEnv("VITE_USER_POOL_ID")}
			mediaBaseUrl={ensureEnv("VITE_MEDIA_BASE_URL")}
			premadeKitsEnabled={ensureEnv("VITE_PREMADE_KITS_ENABLED") === "true"}
			inventoryTypesBlacklist={JSON.parse(
				ensureEnv("VITE_INVENTORY_TYPES_BLACKLIST"),
			)}
			basePlateSizes={ensureEnv("VITE_BASEPLATE_SIZES")
				.split(",")
				.map((s: string) => parseInt(s, 10))}
			inventoryBasePlateSizes={ensureEnv("VITE_INVENTORY_BASEPLATE_SIZES")
				.split(",")
				.map((s: string) => parseInt(s, 10))}
		/>
	</StrictMode>,
	document.getElementById("root"),
);
