import React from "react";
import { gql } from "@apollo/client";
import type { GetDamageLossInventoryStateQuery } from "~/api/get-damage-loss-inventory-state-query.generated.ts";
import getDamageLossInventoryStateQuery from "~/api/get-damage-loss-inventory-state-query.graphql";
import fullInventoryStateFragment from "~/api/full-inventory-state-fragment.graphql";
import type { InventoryStateReportProps } from "../inventory-shared/inventory-state-report.tsx";
import BaseLossReport from "../inventory-shared/base-loss-report.tsx";

function LossReport(
	props: Pick<InventoryStateReportProps, "colours" | "containers">,
) {
	return (
		<BaseLossReport
			{...props}
			query={gql`
				${getDamageLossInventoryStateQuery}
				${fullInventoryStateFragment}
			`}
			transformData={(d: GetDamageLossInventoryStateQuery) =>
				d.damageLossInventoryState
			}
		/>
	);
}

export default LossReport;
