function isFeatureEnabled(name: string) {
	const featuresRaw = import.meta.env.VITE_FEATURE_FLAGS;
	let features: readonly string[];
	try {
		const parsedFeatures = featuresRaw ? JSON.parse(featuresRaw) : undefined;
		if (
			Array.isArray(parsedFeatures) &&
			parsedFeatures.every((f) => typeof f === "string")
		) {
			features = parsedFeatures;
		} else {
			features = [];
		}
	} catch {
		features = [];
	}
	return features.includes(name);
}

export { isFeatureEnabled };
