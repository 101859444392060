import type { ReactElement } from "react";
import React, { useCallback } from "react";
import { useListPaginationContext, useListContext } from "react-admin";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

type LoadMorePaginationProps = {
	readonly idField?: string;
	/** @deprecated Should eventually all have this */
	readonly showTotal?: boolean;
};

// TODO: Seems react-admin isn't set up well for this. Relevant issues to look into:
// https://github.com/marmelab/react-admin/pull/4834
// https://github.com/marmelab/react-admin/issues/1510
// https://github.com/marmelab/react-admin/issues/1787
// https://stackoverflow.com/questions/55737518/how-to-paginate-react-admin-lists-when-the-total-is-unknown
function LoadMorePagination({
	idField = "id",
	showTotal,
}: LoadMorePaginationProps): ReactElement | null {
	const { ids, data, currentSort } = useListContext();
	const lastId = ids[ids.length - 1];
	const lastData = data[lastId];

	const { loading, page, perPage, total, setPage, setPerPage } =
		useListPaginationContext();
	const onFirstClick = useCallback(() => {
		setPage(undefined);
	}, [setPage]);
	const onNextClick = useCallback(() => {
		if (!currentSort) {
			throw new Error("Invalid state");
		}
		if (!lastData || !lastId) {
			throw new Error("Invalid state");
		}
		const sortField = currentSort.field;
		const cursor = JSON.stringify({
			[idField]: lastId,
			[sortField]: lastData[sortField],
		});
		setPage(cursor);
	}, [setPage, lastData, idField, lastId, currentSort]);

	if (!currentSort) {
		return null;
	}
	if (!lastData) {
		return null;
	}
	return (
		<div style={{ textAlign: "right" }}>
			<Typography style={{ display: "inline" }} variant="body2">
				Rows per page:
			</Typography>{" "}
			<Select
				labelId="simple-select-label"
				id="simple-select"
				value={perPage}
				onChange={(e) => {
					setPerPage(e.target.value as number);
				}}
				disabled={loading}
			>
				<MenuItem value={10}>10</MenuItem>
				<MenuItem value={20}>20</MenuItem>
				<MenuItem value={30}>30</MenuItem>
				<MenuItem value={50}>50</MenuItem>
			</Select>
			{showTotal && (
				<Typography style={{ display: "inline" }} variant="body2">
					Total: {total}
				</Typography>
			)}
			<Button
				onClick={onFirstClick}
				disabled={loading || !page}
				color="primary"
			>
				First Page
			</Button>
			<Button
				onClick={onNextClick}
				disabled={loading || ids.length < perPage}
				color="primary"
			>
				Next &gt;
			</Button>
		</div>
	);
}

// const mapStateToProps = state => ({
//   ids: state.admin.resources.events.list.ids,
//   data: state.admin.resources.events.data,
//   total: state.admin.resources.events.list.total,
//   loadedOnce: state.admin.resources.events.list.loadedOnce,
// });
//
// export default connect(
//   mapStateToProps,
//   { crudGetList }
// )(Timeline);

export default LoadMorePagination;
