import React, { useState, useCallback } from "react";
import { Button, useNotify, useRefresh } from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CheckCircle from "@material-ui/icons/CheckCircle";
import People from "@material-ui/icons/People";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import type { DocumentNode } from "@apollo/client/core";
import { useMutation } from "@apollo/client/react";

type ChangeUserButtonProps = {
	readonly mutation: DocumentNode;
	readonly record: Record<string, string>;
	readonly useCopy: boolean;
};

function ChangeUserButton({
	mutation,
	record,
	useCopy,
}: ChangeUserButtonProps) {
	const [isOpen, setOpen] = useState(false);
	const onClose = () => {
		setOpen(false);
	};

	const notify = useNotify();
	const refresh = useRefresh();
	const [changeUser, { loading }] = useMutation(mutation, {
		onCompleted() {
			notify("Project user changed", "info");
			onClose();
			refresh();
		},
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
	});

	const [email, setEmail] = useState("");
	const [copy, setCopy] = useState(false);
	const onConfirm = useCallback(
		async (e) => {
			e.stopPropagation();
			await changeUser({
				variables: {
					id: record.id,
					email,
					copy: useCopy ? copy : undefined,
				},
			});
		},
		[email, record, copy, useCopy, changeUser],
	);

	const onClick = useCallback((e) => {
		e.stopPropagation();
	}, []);

	return (
		<>
			<Button type="button" onClick={() => setOpen(true)} label="Change User">
				<People />
			</Button>
			<Dialog
				open={isOpen}
				onClose={onClose}
				onClick={onClick}
				aria-labelledby="alert-dialog-title"
			>
				<DialogTitle id="alert-dialog-title">Change User</DialogTitle>
				<DialogContent>
					<div>
						<Input
							type="email"
							value={email}
							onChange={(e) => setEmail(e.target.value)}
							placeholder="New user email"
							disabled={loading}
						/>
						{useCopy && (
							<FormControlLabel
								control={
									<Checkbox
										checked={copy}
										onChange={(e) => setCopy(e.target.checked)}
										disabled={loading}
									/>
								}
								label="Copy project"
							/>
						)}
					</div>
				</DialogContent>
				<DialogActions>
					<Button disabled={loading} onClick={onClose} label="Cancel">
						<ErrorOutline />
					</Button>
					<Button
						disabled={loading || email.length === 0}
						onClick={onConfirm}
						label="Confirm"
					>
						<CheckCircle />
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default ChangeUserButton;
