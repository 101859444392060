import React from "react";
import type { BulkItemType } from "./shared-types.ts";
import type { PaletteColour } from "../inventory-shared/data.ts";

type InventoryItemTypeSummaryProps = {
	readonly itemType: BulkItemType;
	readonly colours: readonly PaletteColour[];
};

function InventoryItemTypeSummary({
	colours,
	itemType,
}: InventoryItemTypeSummaryProps) {
	switch (itemType.type) {
		case "brickSeparators":
			return <>Brick separators</>;
		case "logoTile":
			return <>Logo tile</>;
		case "paddedEnvelope":
			return <>Padded envelope</>;
		case "singleBox":
			return <>Single box</>;
		case "doubleBox":
			return <>Double box</>;
		case "tripleBox":
			return <>Triple box</>;
		case "smallFlatpackBox":
			return <>Small flatpack box</>;
		case "packaging":
			return <>Packaging</>;
		case "paperA5":
			return <>Paper A5</>;
		case "paperA4":
			return <>Paper A4</>;
		case "paperLetter":
			return <>Paper Letter</>;
		case "paperA3":
			return <>Paper A3</>;
		case "baseplate":
			return <>Baseplate: {itemType.size}</>;
		case "container":
			return (
				<>
					Container:
					{itemType.containerId} {itemType.colourId}{" "}
					<div
						style={{
							width: 20,
							height: 20,
							display: "inline-block",
							background: colours.find(
								(c) => c.id.toString() === itemType.colourId,
							)?.colour,
						}}
					/>
				</>
			);
		default:
			throw new Error(`Unknown item type: ${JSON.stringify(itemType)}`);
	}
}

export default InventoryItemTypeSummary;
