import React from "react";
import type { Record } from "react-admin";
import {
	Show,
	SimpleShowLayout,
	TextField,
	DateField,
	FunctionField,
} from "react-admin";
import { useInventoryTypesBlacklist } from "~/hooks/inventory.tsx";
import ColourContainerTable from "./colour-container-table.tsx";
import { formatInventoryItemFullValue } from "./format-inventory-item-value.ts";
import InventoryItemValueField from "./inventory-item-value-field.tsx";

function BaseInventoryItemShow({ colours, containers, ...props }: any) {
	const { allowInventoryType } = useInventoryTypesBlacklist();
	return (
		<Show {...props}>
			<SimpleShowLayout>
				<TextField source="id" />
				<DateField source="createdAt" showTime />

				<TextField source="reason" />
				<TextField source="isLoss" />
				<TextField source="reversalOfId" label="Reversal of" />

				<FunctionField
					{...props}
					source="containers"
					render={(record?: Record) => {
						if (!record) {
							return <div />;
						}
						const { containers: containerItems } = record;
						if (!containerItems) {
							return <div />;
						}

						return (
							<ColourContainerTable
								containers={containers}
								colours={colours}
								renderCell={(colour, container) => {
									const item = containerItems.find(
										(i: any) =>
											i.colourId.toString() === colour.id.toString() &&
											i.containerId === container.identifier,
									);
									if (item === undefined) {
										return undefined;
									}
									return formatInventoryItemFullValue(record, item.value);
								}}
							/>
						);
					}}
				/>

				<InventoryItemValueField source="packaging" />
				<InventoryItemValueField source="brickSeparators" />
				<InventoryItemValueField source="smallFlatpackBox" />
				<InventoryItemValueField source="logoTile" />
				{allowInventoryType({ type: "paddedEnvelope" }) && (
					<InventoryItemValueField source="paddedEnvelope" />
				)}
				{allowInventoryType({ type: "singleBox" }) && (
					<InventoryItemValueField source="singleBox" />
				)}
				{allowInventoryType({ type: "doubleBox" }) && (
					<InventoryItemValueField source="doubleBox" />
				)}
				{allowInventoryType({ type: "tripleBox" }) && (
					<InventoryItemValueField source="tripleBox" />
				)}
				{allowInventoryType({ type: "paperA5" }) && (
					<InventoryItemValueField source="paperA5" label="Paper A5" />
				)}
				{allowInventoryType({ type: "paperA4" }) && (
					<InventoryItemValueField source="paperA4" label="Paper A4" />
				)}
				{allowInventoryType({ type: "paperLetter" }) && (
					<InventoryItemValueField source="paperLetter" label="Paper Letter" />
				)}
				{allowInventoryType({ type: "paperA3" }) && (
					<InventoryItemValueField source="paperA3" label="Paper A3" />
				)}
				<FunctionField
					{...props}
					source="baseplates"
					render={(record?: Record) => {
						if (!record) {
							return <div />;
						}
						const { baseplates: baseplateItems } = record;
						if (!baseplateItems) {
							return <div />;
						}

						return (
							<div>
								{baseplateItems
									.filter((item: any) => item.value !== null)
									.map(
										(item: any) =>
											`${item.size}: ${formatInventoryItemFullValue(
												record,
												item.value,
											)}`,
									)
									.join(", ")}
							</div>
						);
					}}
				/>
			</SimpleShowLayout>
		</Show>
	);
}

export default BaseInventoryItemShow;
