import React, { useState } from "react";
import { Title } from "react-admin";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import AddRemoveForm from "./add-remove-form.tsx";
import CountForm from "./count-form.tsx";
import CostPriceForm from "./cost-price-form.tsx";
import useInventoryData from "../inventory-shared/use-inventory-data.ts";
import useInventoryState from "./use-inventory-state.ts";

type TabId = "add" | "count" | "costPrice";

const tabs = [
	{ id: "add" as TabId, component: AddRemoveForm, label: "Add/Remove" },
	{ id: "count" as TabId, component: CountForm, label: "Count" },
	{ id: "costPrice" as TabId, component: CostPriceForm, label: "Cost Price" },
];

function ManageInventory() {
	const [tab, setTab] = useState<TabId>("add");
	const TabContent = tabs.find((t) => t.id === tab)?.component;

	const { colours, containers } = useInventoryData();
	const { inventoryState } = useInventoryState();

	return (
		<div>
			<Title title="Manage inventory" />
			{tabs.map((t) => (
				<Button
					key={t.id}
					type="button"
					onClick={() => setTab(t.id)}
					value={t.id}
					variant={t.id === tab ? "outlined" : undefined}
					color={t.id === tab ? "primary" : undefined}
				>
					{t.label}
				</Button>
			))}
			{TabContent && (
				<Card>
					<CardContent>
						<TabContent
							colours={colours}
							containers={containers}
							inventoryState={inventoryState}
						/>
					</CardContent>
				</Card>
			)}
		</div>
	);
}

export default ManageInventory;
