import { gql } from "@apollo/client";
import type { GraphQLResourceDefinition } from "~/api/graphql-resource-definition.d.ts";

export default {
	GET_LIST: {
		name: "userList",
		useListAndCount: true,
		itemFragment: gql`
			fragment usersItem on AdminUser {
				id
				joinedAt
				emailAddress
				fullName
				currentSite
			}
		`,
	},
	GET_ONE: {
		name: "userById",
		itemFragment: gql`
			fragment userByIdItem on AdminUser {
				id
				joinedAt
				emailAddress
				givenName
				middleName
				familyName
				fullName
				howHeard
				country
				birthDate
				mailchimpLastUpdatedAt
				mailchimpSentTags
				originSite
				currentSite
				ipAddress
				ipAddressCountry
			}
		`,
	},
	DELETE: {
		name: "deleteUser",
	},
} as GraphQLResourceDefinition;
