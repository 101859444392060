import type { ReactElement } from "react";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ImageField } from "react-admin";

const useStyles = makeStyles({
	imgContainer: {
		"& img": {
			height: 150,
			width: 250,
			objectFit: "contain",
		},
	},
});

export default function ListThumbnailField(props: any): ReactElement {
	const imageFieldClasses = useStyles();
	return <ImageField {...props} className={imageFieldClasses.imgContainer} />;
}
