query getPricingModel {
	pricingModel {
		numColoursPremiumThreshold
		numColoursPremium
		discountBrackets {
			areaMetres
			discount
		}
		basePlateSizePrices {
			size
			price {
				amount
				currencyCode
			}
		}
	}
}