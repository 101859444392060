import React, { useState } from "react";
import useInventoryLegacyState from "./use-inventory-legacy-state.ts";
import type { InventoryStateReportProps } from "../inventory-shared/inventory-state-report.tsx";
import BaseOnHandReport from "../inventory-shared/base-on-hand-report.tsx";

function OnHandReportLegacy(
	props: Pick<InventoryStateReportProps, "colours" | "containers">,
) {
	const [values, setValues] = useState<{ date?: Date } | undefined>(undefined);
	const { loading, inventoryState } = useInventoryLegacyState(values?.date, {
		skip: !values,
	});

	return (
		<BaseOnHandReport
			{...props}
			state={inventoryState}
			loading={loading}
			onValuesChange={setValues}
			values={values}
		/>
	);
}

export default OnHandReportLegacy;
