import type { ReactNode } from "react";
import React, { createContext, useContext, useMemo } from "react";

type ContextValue = {
	readonly basePlateSizes: readonly number[];
	readonly inventoryBasePlateSizes: readonly number[];
};

const Context = createContext<ContextValue | undefined>(undefined);

type BasePlateSizesProviderProps = {
	readonly basePlateSizes: readonly number[];
	readonly inventoryBasePlateSizes: readonly number[];
	readonly children: ReactNode;
};

function BasePlateSizesProvider({
	basePlateSizes,
	inventoryBasePlateSizes,
	children,
}: BasePlateSizesProviderProps) {
	const value = useMemo(
		() => ({
			basePlateSizes,
			inventoryBasePlateSizes,
		}),
		[basePlateSizes, inventoryBasePlateSizes],
	);
	return <Context.Provider value={value}>{children}</Context.Provider>;
}

function useBasePlateSizes() {
	const basePlateSizes = useContext(Context);
	if (!basePlateSizes) {
		throw new Error("No shopify url context");
	}
	return basePlateSizes.basePlateSizes;
}

function useInventoryBasePlateSizes() {
	const basePlateSizes = useContext(Context);
	if (!basePlateSizes) {
		throw new Error("No shopify url context");
	}
	return basePlateSizes.inventoryBasePlateSizes;
}

export {
	BasePlateSizesProvider,
	useInventoryBasePlateSizes,
	useBasePlateSizes,
};
