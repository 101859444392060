import React, { useState } from "react";
import { DateTimeInput, useNotify } from "react-admin";
import { Form } from "react-final-form";
import type { DocumentNode } from "graphql";
import Button from "@material-ui/core/Button";
import { useQuery } from "@apollo/client/react";
import type { AdminInventoryState } from "~/api/types.ts";
import type { InventoryStateReportProps } from "./inventory-state-report.tsx";
import InventoryStateReport from "./inventory-state-report.tsx";

type FilterValues = {
	readonly from?: Date;
	readonly to?: Date;
};

type StateQueryVariables = {
	readonly from?: string;
	readonly to?: string;
};

type BaseLossReportProps<TQuery> = Pick<
	InventoryStateReportProps,
	"colours" | "containers"
> & {
	readonly query: DocumentNode;
	readonly transformData: (data: TQuery) => AdminInventoryState;
};

function BaseLossReport<TQuery>({
	colours,
	transformData,
	query,
	containers,
}: BaseLossReportProps<TQuery>) {
	const [filters, setFilters] = useState<FilterValues | undefined>(undefined);
	const notify = useNotify();
	const { loading, data } = useQuery<TQuery, StateQueryVariables>(query, {
		variables: {
			from: filters?.from ? filters.from.toISOString() : undefined,
			to: filters?.to ? filters.to.toISOString() : undefined,
		},
		skip: !filters,
		fetchPolicy: "no-cache",
		onError(e) {
			notify(e.toString(), { type: "error" });
		},
	});
	const inventoryState = data ? transformData(data) : undefined;
	const onSubmit = ({ from, to }: any) => {
		setFilters({
			from,
			to,
		});
	};

	return (
		<div>
			<Form
				onSubmit={onSubmit}
				render={({ handleSubmit }) => (
					<form
						onSubmit={handleSubmit}
						style={{ display: "flex", alignItems: "center" }}
					>
						<DateTimeInput
							source="from"
							helperText="Leave blank for all past"
							disabled={loading}
						/>
						<DateTimeInput
							source="to"
							helperText="Leave blank for current"
							disabled={loading}
						/>
						<Button
							type="submit"
							color="primary"
							variant="contained"
							disabled={loading}
						>
							{loading ? "Generating" : "Generate"}
						</Button>
					</form>
				)}
			/>
			{inventoryState && (
				<InventoryStateReport
					state={inventoryState}
					baseFilename="damage-loss"
					colours={colours}
					containers={containers}
				/>
			)}
		</div>
	);
}

export default BaseLossReport;
