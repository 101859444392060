import { gql } from "@apollo/client";

const createAddRemoveInventoryMutation = gql`
	mutation createAddRemoveInventoryMutation(
		$containers: [CreateaddremoveinventoryContainers!]!
		$baseplates: [CreateaddremoveinventoryBaseplates!]!
		$reason: String!
		$brickSeparators: Int
		$logoTile: Int
		$paddedEnvelope: Int
		$singleBox: Int
		$doubleBox: Int
		$tripleBox: Int
		$smallFlatpackBox: Int
		$packaging: Int
		$paperA5: Int
		$paperA4: Int
		$paperLetter: Int
		$paperA3: Int
	) {
		createAddRemoveInventory(
			containers: $containers
			baseplates: $baseplates
			reason: $reason
			brickSeparators: $brickSeparators
			logoTile: $logoTile
			paddedEnvelope: $paddedEnvelope
			singleBox: $singleBox
			doubleBox: $doubleBox
			tripleBox: $tripleBox
			smallFlatpackBox: $smallFlatpackBox
			packaging: $packaging
			paperA5: $paperA5
			paperA4: $paperA4
			paperLetter: $paperLetter
			paperA3: $paperA3
		) {
			__typename
			id
		}
	}
`;

export default createAddRemoveInventoryMutation;
