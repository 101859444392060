import React from "react";
import { useMutation } from "@apollo/client/react";
import { useNotify, minValue, useRedirect } from "react-admin";
import { mapValues } from "lodash-es";
import createCostPriceInventoryMutation from "~/api/create-cost-price-inventory-mutation.ts";
import { useInventoryBasePlateSizes } from "~/hooks/base-plate-sizes.tsx";
import type { InventoryFormProps } from "./inventory-form.tsx";
import InventoryForm from "./inventory-form.tsx";

function numToString(dollars?: number): string | undefined {
	return dollars?.toString();
}

function CostPriceForm(
	props: Pick<InventoryFormProps, "colours" | "containers" | "inventoryState">,
) {
	const notify = useNotify();
	const redirect = useRedirect();
	const [createCostPrice, costPriceResult] = useMutation(
		createCostPriceInventoryMutation,
		{
			onCompleted() {
				notify("Cost price added", "info");
				redirect("list", "/inventoryItems");
			},
			onError(e) {
				notify(e.toString(), { type: "error" });
			},
		},
	);

	const basePlateSizes = useInventoryBasePlateSizes();
	const onSave = async ({ containers, baseplates, ...restValues }: any) => {
		await createCostPrice({
			variables: {
				...mapValues(restValues, numToString),
				baseplates: basePlateSizes
					.map((size) => ({
						size,
						value: numToString(
							baseplates.find((b: any) => b.size === size.toString())?.value,
						),
					}))
					.filter((b) => !Number.isNaN(parseFloat(b.value ?? ""))),
				containers: containers
					?.filter((c: any) => c.value !== null)
					?.map((c: any) => ({
						...c,
						value: numToString(c.value),
					})),
			},
		});
	};

	return (
		<InventoryForm
			actionName="Save Cost Prices"
			onSave={onSave}
			saving={costPriceResult.loading}
			cellValidate={minValue(0)}
			cellStep={0.01}
			{...props}
		/>
	);
}

export default CostPriceForm;
